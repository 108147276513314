































import { NftGalleryModel } from '@/models/nft-gallery-model'
import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: () => [] }) nfts!: NftGalleryModel[]
  @Prop({ default: () => [] }) decoratedNfts
  @Prop({ default: () => '' }) type
  selectedNft = null as any
  popoStore = popoStore

  @Watch('decoratedNfts', { immediate: true }) decoratorsChanged(decoratedNfts) {
    const index = decoratedNfts.findIndex((item) => item.type === this.type)
    this.selectedNft = index === -1 ? null : { ...decoratedNfts[index] }
  }

  get itemPerRow() {
    return this.$vuetify.breakpoint.lgAndUp ? 4 : 3
  }
  get totalRows() {
    return Math.ceil(this.nfts.length / this.itemPerRow)
  }
}
