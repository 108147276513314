var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column gap-3 gap-sm-4 hide-scroll galery-frame",class:{
    ' pa-4': !_vm.$vuetify.breakpoint.smOnly,
    ' pa-2': _vm.$vuetify.breakpoint.smOnly,
  }},_vm._l((_vm.totalRows),function(rowItem,rowIndex){return _c('div',{key:rowIndex,staticClass:"d-flex table-container gap-3 gap-sm-4"},_vm._l((_vm.itemPerRow),function(i){return _c('div',{key:i,staticClass:"overflow-hidden table-cell",class:{
        'highlight-border':
          _vm.selectedNft &&
          _vm.nfts[rowIndex * _vm.itemPerRow + i - 1] &&
          _vm.selectedNft.id == _vm.nfts[rowIndex * _vm.itemPerRow + i - 1].id,
      }},[(rowIndex * _vm.itemPerRow + i <= _vm.nfts.length)?_c('div',{staticClass:"pa-2 px-0 primary darken-1",on:{"click":function($event){return _vm.popoStore.changeDecoratedNfts(_vm.nfts[rowIndex * _vm.itemPerRow + i - 1])}}},[_c('popo-image',{attrs:{"decorators":[_vm.nfts[rowIndex * _vm.itemPerRow + i - 1]]}})],1):_vm._e()])}),0)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }